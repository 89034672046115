import React, { useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../index.js';
import FooterComponent from './FooterComponent';
import getGoogleAccessToken from '../utils/getGoogleAccessToken';
import LinkedInAuth from './LinkedInAuth.js';

function LoginComponent({ userAuthenticationService }) {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthChange = async user => {
      if (user) {
        try {
          // Retrieve the Google access token
          const token = await getGoogleAccessToken(user);

          // Update the user object with the token
          const googleUser = { accessToken: token };
          userAuthenticationService.setUser(googleUser);

          // Navigate only after setting the user
          navigate('/'); // Redirect to home page or desired route on successful sign-in
        } catch (error) {
          console.error('Error fetching Google access token:', error);
        }
      }
      setIsSignedIn(!!user);
    };

    const unregisterAuthObserver = onAuthStateChanged(auth, user => {
      handleAuthChange(user);
    });

    return () => unregisterAuthObserver(); // Unregister Firebase observers when the component unmounts
  }, [navigate, userAuthenticationService]);

  useEffect(() => {
    // Check if there's a redirect result when the component mounts
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          setIsSignedIn(!!result.user);
          userAuthenticationService.setUser(result.user);
          navigate('/'); // Redirect to the home page or another desired route on successful sign-in
        }
      } catch (error) {
        setError(error.message); // Handle any errors
      }
    };

    checkRedirectResult();
  }, [navigate, userAuthenticationService]);

  const handleEmailSignIn = async event => {
    event.preventDefault();
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setError('Password and email combination invalid.');
      } else {
        setError(error.message); // Handle other errors
      }
    }
  };

  // const handleGoogleSignIn = async () => {
  //   const provider = new GoogleAuthProvider();
  //   try {
  //     await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
  //     // await signInWithPopup(auth, provider);
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  const openLinkedInPopup = () => {
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      '/linkedin-auth',
      'LinkedInAuth',
      `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`
    );
  };

  const handleForgotPassword = async () => {
    setError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError(error.message); // Handle any errors during the password reset process
    }
  };

  if (isSignedIn) {
    navigate('/');
    return null; // If signed in, no need to show login form
  }

  return (
    <div className="flex h-screen flex-col bg-gray-100">
      <div className="flex flex-grow items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-8">
          <div className="mx-auto space-y-4 rounded-lg bg-secondary-dark py-8 px-8">
            <div className="space-y-2 text-center">
              <h2 className="text-2xl font-semibold text-primary-active">Login</h2>
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <form
              onSubmit={handleEmailSignIn}
              className="space-y-4"
            >
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <div className="text-right">
                <button
                  onClick={handleForgotPassword}
                  className="text-sm text-primary-active hover:underline"
                >
                  Forgot Password?
                </button>
              </div>
              <div className="flex flex-col space-y-4">
                <button
                  type="submit"
                  className="w-full rounded-lg bg-primary-active py-2 text-white transition hover:bg-primary-dark"
                >
                  Sign in with Email
                </button>
                <button
                  onClick={openLinkedInPopup}
                  type="button"
                  className="flex w-full items-center justify-center space-x-2 rounded-lg border border-gray-300 bg-white py-2 font-medium text-black hover:bg-gray-300"
                >
                  <img
                    src="./assets/linkedin-logo.svg"
                    alt="LinkedIn logo"
                    className="h-5 w-5"
                  />
                  <span>Sign in with LinkedIn</span>
                </button>
                <div className="text-center">
                  <p
                    className="cursor-pointer text-sm text-primary-active hover:underline"
                    onClick={() => navigate('/signup')}
                  >
                    Or make an account
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default LoginComponent;
