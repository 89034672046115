import React from 'react';

const FooterComponent = () => {
  return (
    <footer className="bg-gray-100 py-4 text-center text-sm">
      <div className="text-blue-400">
        <a
          href="https://www.nuclivision.com/privacy-policy"
          target="_blank"
          className="mx-4 inline-block hover:underline"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.nuclivision.com/terms-conditions"
          target="_blank"
          className="mx-4 inline-block hover:underline"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
