import React from 'react';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import LoginComponent from '../components/LoginComponent';
import { auth } from '../index.js';
import SignupComponent from '../components/SignupComponent';
import LinkedInAuth from '../components/LinkedInAuth';

function LogoutComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      try {
        await auth.signOut(); // Wait for the sign-out to complete
        localStorage.setItem('signoutEvent', 'true');
        navigate('/login'); // Navigate to the desired route after logout
      } catch (error) {
        console.error('Error during sign out:', error);
        // Optionally handle the error (e.g., show an error message)
      }
    };

    logout(); // Call the async logout function
  }, [navigate]);

  return null;
}

function FirebaseAuthConnectRoutes({ userAuthenticationService }) {
  const navigate = useNavigate();

  //for multi-tab logout
  useEffect(() => {
    localStorage.removeItem('signoutEvent');
    const storageEventListener = event => {
      const signOutEvent = localStorage.getItem('signoutEvent');
      if (signOutEvent) {
        navigate(`/logout`);
      }
    };

    window.addEventListener('storage', storageEventListener);

    return () => {
      window.removeEventListener('storage', storageEventListener);
    };
  }, [navigate]);

  useEffect(() => {
    const getAuthorizationHeader = () => {
      const user = userAuthenticationService.getUser();

      // if the user is null return early, next time
      // we hit this function we will have a user
      if (!user) {
        return;
      }

      return {
        Authorization: `Bearer ${user.accessToken}`,
      };
    };

    const handleUnauthenticated = () => {
      navigate('/login');

      // return null because this is used in a react component
      return null;
    };

    userAuthenticationService.set({ enabled: true });

    userAuthenticationService.setServiceImplementation({
      getAuthorizationHeader,
      handleUnauthenticated,
    });
  }, [navigate]);

  return (
    <Routes>
      <Route
        path="/login"
        element={<LoginComponent userAuthenticationService={userAuthenticationService} />}
      />
      <Route
        path="/logout"
        element={<LogoutComponent />}
      />
      <Route
        path="/signup"
        element={<SignupComponent userAuthenticationService={userAuthenticationService} />}
      />
      <Route
        path="/linkedin-auth"
        element={<LinkedInAuth />}
      />
    </Routes>
  );
}

export default FirebaseAuthConnectRoutes;
